<template>
  <div :class="b()">
    <div :class="b('title')">
      {{ title }}
    </div>
    <ul :class="b('list')">
      <li
        v-for="(item, index) in items"
        :key="index"
        :class="b('item')"
      >
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  // type Setup = {}
  // type Data = {}

  /**
   *
   */
  export default defineComponent({
    name: 'c-legend-list',

    // components: {},

    props: {
      /**
       * The title to be displayed.
       */
      title: {
        type: String,
        default: 'Legende',
      },

      /**
       * The items to be displayed.
       */
      items: {
        type: Array,
        default: () => [],
      },
    },
    // emits: [],

    // setup(): Setup {
    //   return {};
    // },
    // data(): Data {
    //   return {};
    // },

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use 'sass:map';
  @use 'vuetify/settings' as vuetify;
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-legend-list {
    $this: &;

    &__title {
      font-family: variables.$font-family-default-condensed;
      font-weight: bold;
      margin-bottom: 5px;
    }

    &__list,
    &__item {
      list-style: none;
    }

    &__item {
      line-height: 1.4em;
      color: variables.$color-grayscale--100;
    }
  }
</style>
