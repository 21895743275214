<template>
  <div :class="b('', { type })">
    <template v-if="headline">
      {{ headline }}
    </template>
    <span
      v-if="subline"
      :class="b('subline')"
    >
      {{ subline }}
    </span>

    <slot name="title"></slot>
    <span
      v-if="$slots.subline"
      :class="b('subline')"
    >
      <slot name="subline"></slot>
    </span>
  </div>
</template>

<script lang="ts">
  import { PropType, defineComponent } from 'vue';

  type Variants = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

  export default defineComponent({
    name: 'e-headline',

    props: {
      /**
       * The headline text
       */
      headline: {
        type: String,
        default: '',
      },

      /**
       * The subline text
       */
      subline: {
        type: String,
        default: '',
      },

      /**
       * The headline type
       */
      type: {
        type: String as PropType<Variants>,
        default: 'h2',
        validator: (value: string) => ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(value),
      },
    },

    // data() {}
    // computed() {}
    // watch() {}
    // methods() {}
    // setup() {}
    // render() {}
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .e-headline {
    display: grid;
    grid-template-rows: auto;
    font-family: variables.$font-family-babas;
    text-transform: uppercase;
    font-weight: variables.$font-weight--semi-bold;

    &--type-h1 {
      @include mixins.heading-h1;

      margin-bottom: variables.$spacing--35;
      text-align: center;

      @include mixins.media(sm) {
        text-align: left;
      }
    }

    &--type-h2 {
      @include mixins.heading-h2;

      margin-bottom: variables.$spacing--25;
    }

    &--type-h3 {
      @include mixins.heading-h3;

      margin-bottom: variables.$spacing--15;
    }

    &--type-h4 {
      @include mixins.heading-h4;

      margin-bottom: variables.$spacing--15;
    }

    &--type-h5 {
      @include mixins.heading-h5;

      margin-bottom: variables.$spacing--15;
    }

    &--type-h6 {
      @include mixins.heading-h6;

      margin-bottom: variables.$spacing--15;
    }

    &__subline {
      @include mixins.heading-h4;
    }
  }
</style>
