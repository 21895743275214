<template>
  <div
    :class="b()"
    :data-id="playerId"
  >
    {{ playerName }}
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import hdDataHelper from '@/helpers/hd-data-helper';

  // type Setup = {}
  // type Data = {}

  /**
   *
   */
  export default defineComponent({
    name: 'c-player-name',

    // components: {},

    props: {
      /**
       * The player number
       */
      playerId: {
        type: Number,
        default: null,
      },

      /**
       * The player number
       */
      firstName: {
        type: String,
        default: '',
      },

      /**
       * The player number
       */
      lastName: {
        type: String,
        default: '',
      },

      /**
       * Only display the first name.
       */
      onlyFirstName: {
        type: Boolean,
        default: false,
      },

      /**
       * Only display the last name.
       */
      onlyLastName: {
        type: Boolean,
        default: false,
      },
    },
    // emits: [],

    // setup(): Setup {
    //   return {};
    // },
    // data(): Data {
    //   return {};
    // },

    computed: {
      playerName(): string {
        const output = [];

        if (!this.onlyLastName) {
          output.push(hdDataHelper.getPersonName(this.firstName));
        }

        if (!this.onlyFirstName) {
          output.push(hdDataHelper.getPersonName(this.lastName));
        }

        return output.join(' ');
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use 'sass:map';
  @use 'vuetify/settings' as vuetify;
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-player-name {
    $this: &;
  }
</style>
