import { TeamSeasonConfig } from '@/types/leauge-config';

export const Season202425: TeamSeasonConfig = {
  divisionId: 16659,
  standings: [
    {
      divisionId: 16659,
      playoffCut: 4,
    },
  ],
  playoffId: null,
  teamId: 49205,
  seasonLabel: '2024-25',
};

export default Season202425;
