import { TeamSeasonConfig } from '@/types/leauge-config';

export const Season202425: TeamSeasonConfig = {
  divisionId: 16271,
  standings: [
    {
      divisionId: 16271,
    },
  ],
  playoffId: null,
  teamId: 41347,
  seasonLabel: '2024-25',
  streamingFallbackUrl: 'https://www.red.sport/en-at/page/debl2'
};

export default Season202425;
