import HockeyData from '@/types/hockey-data';

const HOCKEY_DATA: HockeyData = {
  nameErrorMap: [
    '*',
    '**',
    '(j)',
    '(koop)',
    'F1',
    'F2',
    'F3',
    'F4',
    'Joker',
    'K1',
    'K2',
    'K3',
    'K4',
    'K5',
    ' E ',
    'U24',
    'OEL',
  ],
  goalieAbbr: ['G'],
  forwardAbbr: ['F', 'LF', 'C', 'RF'],
  defenseAbbr: ['D', 'LD', 'RD'],
  PENALTY_FALLBACK: 'Strafe',
  PENALTIES_MAPPING: {
    'abuse': 'Beschimpfung von Offiziellen',
    'critic': 'Kritik am Entscheid',
    'bench': 'Kleine Bankstrafe',
    'trip': 'Beinstellen',
    'hi-st': 'Hoher Stock',
    'intrf': 'Behinderung',
    'hook': 'Haken',
    'hold': 'Halten',
    'slash': 'Stockschlag',
    'elbow': 'Ellbogencheck',
    'clip': 'Check gegen das Knie',
    'knee': 'Check mit dem Knie',
    'charg': 'Unerlaubter Körperangriff',
    'too-m': 'Zu viele Spieler auf dem Eis',
    'un-sp': 'Unsportliches Verhalten',
    'dive': 'Schwalbe',
    'board': 'Check gegen die Bande',
    'ho-st': 'Halten des Stockes',
    'delay': 'Spielverzögerung',
    'rough': 'Übertriebene Härte',
    'cross': 'Cross-Checking',
    'che-h': 'Check gegen den Kopf',
    'che-b': 'Check von hinten',
    'spear': 'Stockstich',
    'fight': 'Faustkampf',
    'pen-s': 'Penalty-Schuss',
    'misc': 'Disziplinarstrafe',
    'match': 'Matchstrafe',
    'ga-mi': 'Spieldauer-Disziplinarstrafe',
  },
};

export default HOCKEY_DATA;
