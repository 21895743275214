import { TeamConfig, TeamSeasonConfig } from '@/types/leauge-config';
import leagueConfig from '@/setup/js/leagues/config';

export default function getTeamConfig(divisionId: number): TeamConfig | undefined {
  let config: TeamConfig | undefined;

  leagueConfig.teams.forEach(teamConfig => {
    if (!config && teamConfig.seasons.find(season => getTeamSeasonByDivisionId(season, divisionId))) {
      config = teamConfig;
    }
  });

  return config;
}

export function getTeamSeasonConfig(divisionId: number): TeamSeasonConfig | undefined {
  let config: TeamSeasonConfig | undefined;

  leagueConfig.teams.forEach(team => {
    if (!config) {
      config = team.seasons.find(season => getTeamSeasonByDivisionId(season, divisionId));
    }
  });

  return config;
}

export function getTeamSeasonByDivisionId(season: TeamSeasonConfig, divisionId: number): TeamSeasonConfig | null {
  if (
    season.divisionId === divisionId
    || season.standings.find(standing => standing.divisionId === divisionId)
    || season.playoffId === divisionId
  ) {
    return season;
  }

  return null;
}
