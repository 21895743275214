<template>
  <div :class="b()">
    <template v-if="showHash">#</template>{{ value }}
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  // type Setup = {}
  // type Data = {}

  /**
   *
   */
  export default defineComponent({
    name: 'e-player-number',

    // components: {},

    props: {
      /**
       * The number,
       */
      value: {
        type: [Number, String],
        default: '',
      },

      /**
       * The number,
       */
      showHash: {
        type: Boolean,
        default: true,
      },
    },
    // emits: [],

    // setup(): Setup {
    //   return {};
    // },
    // data(): Data {
    //   return {};
    // },

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use 'sass:map';
  @use 'vuetify/settings' as vuetify;
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .e-player-number {
    $this: &;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 30px;
    border-radius: 20%;
    background: variables.$color-grayscale--800;
    aspect-ratio: 1;
    font-size: variables.$font-size--small;
    font-family: variables.$font-family-upbolters;

    @include mixins.media(sm) {
      width: 40px;
      font-size: variables.$font-size--default;
    }
  }
</style>
