<template>
  <component
    :is="type"
    :class="b({ size, showFlat, type })"
    :href="type === 'a' ? urlToDetailPage : null"
  >
    <img
      :src="imageUrl"
      :alt="teamName"
      loading="lazy"
      width="45"
      height="45"
      @error="replaceByDefault"
    />
  </component>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import config from '@/setup/js/config';
  import { TEAM } from '@/setup/routes-constants';
  import useSessionStore from '@/stores/session';

  type Setup = {
    sessionStore: ReturnType<typeof useSessionStore>;
  };

  export default defineComponent({
    name: 'c-team-logo',
    // components: {},
    // mixins: [],

    props: {
      /**
       * The id of the team.
       */
      teamId: {
        type: Number,
        required: true,
      },

      /**
       * The the division id.
       */
      divisionId: {
        type: Number,
        default: null,
      },

      /**
       * The name of the team.
       */
      teamName: {
        type: String,
        default: 'TeamLogo',
      },

      /**
       * Show the logo flat.
       */
      showFlat: {
        type: Boolean,
        default: false,
      },

      /**
       * The size of the logo.
       */
      size: {
        type: String,
        default: 'md',
        validator: (value: string) => ['xs', 'sm', 'md', 'lg', 'xl'].includes(value),
      },

      /**
       * Define if the logo should link to the team page.
       */
      linkToTeamPage: {
        type: Boolean,
        default: false,
      },
    },
    setup(): Setup {
      return {
        sessionStore: useSessionStore(),
      };
    },
    data() {
      return {
        defaultImage: 'https://dummyimage.com/200x200/bdbdbd/bdbdbd.jpg',
        imageUrl: '',
      };
    },

    computed: {
      type(): string {
        return this.linkToTeamPage ? 'a' : 'div';
      },

      urlToDetailPage(): string {
        const divisionId = this.divisionId || this.sessionStore.activeSeason.divisionId;

        const detailPage = this.$router.resolve({
          name: TEAM.name,
          params: { teamId: this.teamId, divisionId },
        });

        return detailPage?.href;
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    mounted() {
      this.imageUrl = this.teamId ? `${config.LOGO_BASE_URL_API}/${this.teamId}.png` : this.defaultImage;
    },
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeDestroy() {},
    // destroyed() {},

    methods: {
      replaceByDefault() {
        this.imageUrl = this.defaultImage;
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-team-logo {
    --c-team-logo-image-width: 45px;
    --c-team-logo-image-width-sm: 50px;
    --c-team-logo-image-width-md: 60px;
    --c-team-logo-image-width-lg: 70px;

    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--c-team-logo-image-width);
    aspect-ratio: auto 1/1;

    @include mixins.media(sm) {
      width: var(--c-team-logo-image-width-sm);
    }

    @include mixins.media(md) {
      width: var(--c-team-logo-image-width-md);
    }

    @include mixins.media(lg) {
      width: var(--c-team-logo-image-width-lg);
    }

    &--type-button {
      all: unset;
      outline: revert;
      cursor: pointer;
    }

    &--size-xs {
      --c-team-logo-image-width: 25px;
      --c-team-logo-image-width-sm: 35px;
      --c-team-logo-image-width-md: 35px;
      --c-team-logo-image-width-lg: 35px;
    }

    &--size-sm {
      --c-team-logo-image-width: 35px;
      --c-team-logo-image-width-sm: 45px;
      --c-team-logo-image-width-md: 45px;
      --c-team-logo-image-width-lg: 45px;
    }

    &--size-md {
      --c-team-logo-image-width: 45px;
      --c-team-logo-image-width-sm: 50px;
      --c-team-logo-image-width-md: 60px;
      --c-team-logo-image-width-lg: 70px;
    }

    &--size-lg {
      --c-team-logo-image-width: 60px;
      --c-team-logo-image-width-sm: 70px;
      --c-team-logo-image-width-md: 80px;
      --c-team-logo-image-width-lg: 90px;
    }

    &--size-xl {
      --c-team-logo-image-width: 80px;
      --c-team-logo-image-width-sm: 120px;
      --c-team-logo-image-width-md: 130px;
      --c-team-logo-image-width-lg: 140px;
    }

    .v-lazy-image {
      opacity: 0;
      transition: opacity 0.25s;
    }

    .v-lazy-image-loaded {
      opacity: 1;
    }

    img {
      width: 100%;
      height: 100%;
      aspect-ratio: auto 1/1;
      object-fit: cover;
      overflow: initial;
      overflow-clip-margin: initial;
    }

    &:not(&--show-flat) {
      img {
        border-radius: 50%;
        box-shadow: 0 1px 10px 0 rgba(variables.$color-black, 0.15);

        @include mixins.media(sm) {
          box-shadow: 0 5px 15px 0 rgba(variables.$color-black, 0.15);
        }

        @include mixins.media($media: print) {
          box-shadow: 0 5px 15px 0 rgba(variables.$color-black, 0);
        }
      }
    }
  }
</style>
