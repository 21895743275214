<template>
  <a
    v-if="randomSponsor"
    :class="b()"
    :href="randomSponsor.url"
    target="_blank"
    rel="noopener noreferrer"
  >
    <img
      :class="b('image')"
      :src="randomSponsor.logo"
    />
  </a>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import Kinothek from '@/assets/premium-sponsors/kinothek-blue.svg';
  import SaminaLogo from '@/assets/premium-sponsors/samina-blue.svg';

  type Sponsor = {
    logo: string;
    title: string;
    url: string;
  };

  // type Setup = {}
  type Data = {
    randomSponsor: Sponsor | null;
    premium: Sponsor[];
  };

  /**
   *
   */
  export default defineComponent({
    name: 'c-sponsor-logo',

    // components: {},

    // props: {},
    // emits: [],

    // setup(): Setup {
    //   return {};
    // },
    data(): Data {
      return {
        randomSponsor: null,
        premium: [
          { logo: SaminaLogo, title: 'Samina', url: 'https://www.google.com' },
          { logo: Kinothek, title: 'Samina', url: 'https://www.google.com' },
        ],
      };
    },

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    mounted() {
      this.randomSponsor = this.premium.sort(() => 0.5 - Math.random())?.[0] || null;
    },
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use 'sass:map';
  @use 'vuetify/settings' as vuetify;
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-sponsor-logo {
    $this: &;

    &__image {
      width: 100px;
    }
  }
</style>
