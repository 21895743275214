import dayjs, { Dayjs } from 'dayjs';
import appConfig from '@/setup/js/config';
import playerConfig from '@/setup/js/players';
import { GameSchedule } from '@/types/game-schedule';
import { GameKnockout } from '@/types/knockout-stage-response';

/**
 * This is a list of helpers to transform or change the odd hockey data api data structure.
 */
export default {
  /**
   * Returns if a game has started.
   */
  hasGameStarted(timestamp: number, gameStatus: number): boolean {
    const gameDate = dayjs(timestamp);

    return dayjs().isAfter(gameDate) && gameStatus > 0;
  },

  /**
   * Returns if a game has started.
   */
  hasGameEnded(timestamp: number, gameStatus: number, endTime?: string): boolean {
    const gameDate = dayjs(timestamp);

    return !!endTime || (dayjs().add(5, 'hours').isAfter(gameDate) && gameStatus > 0);
  },

  /**
   * Returns if a dayjs date object.
   */
  getDateObject({
    utcTimestamp,
    dateString,
    timeString,
  }: {
    utcTimestamp?: number;
    dateString?: string;
    timeString?: string;
  }): Dayjs {
    if (utcTimestamp) {
      return dayjs(utcTimestamp);
    }

    if (timeString) {
      return dayjs(timeString, appConfig.TIME_FORMAT);
    }

    return dayjs(dateString, appConfig.DATE_FORMAT);
  },

  hasHomeTeamWon(game: GameSchedule | GameKnockout): boolean {
    return game.homeTeamScore > game.awayTeamScore;
  },

  hasAwayTeamWon(game: GameSchedule | GameKnockout): boolean {
    return game.homeTeamScore < game.awayTeamScore;
  },

  getPersonFullName(lastname: string, firstname: string, shortenFirstname = false): string {
    if (shortenFirstname) {
      return `${this.getPersonName(lastname)} ${this.getPersonName(firstname).charAt(0)}.`;
    }

    return `${this.getPersonName(lastname)} ${this.getPersonName(firstname)}`;
  },

  getPersonName(name: string): string {
    // Remove unwanted strings from the name
    playerConfig.nameErrorMap.forEach((unwanted) => {
      name = name.replace(unwanted, '');
    });

    // Trim, split by spaces, and capitalize each part of the name
    return name
      .trim()
      .split(' ')
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
      .join(' ');
  },
};
