<template>
  <v-card :class="b('', { disabled: player.isDisabled })">
    <div :class="b('image-container')">
      <e-player-number
        :value="player.playerJerseyNr"
        :class="b('jersey-number')"
      />
      <v-img
        v-if="showPlayerImage && !player.isDisabled"
        :class="b('image')"
        :src="player.images.playerSmallPortrait"
        cover
      />
      <div
        v-else
        :class="b('image', { team: true })"
        :style="{ backgroundImage: `url(${player.teamImage})` }"
      ></div>
    </div>

    <v-card-item
      :class="b('header')"
      :title="getFullname(player.playerLastname, player.playerFirstname)"
    >
      <template #subtitle>{{ player.nation }} - Alter: {{ player.age }}</template>
    </v-card-item>

    <div
      v-if="player.isDisabled"
      :class="b('fallback-text')"
    >
      <template v-if="isFemale">
        Diese Spielerin ist spielberechtigt aber hat noch keine Spiele gespielt.
      </template>
      <template v-else>
        Dieser Spieler ist spielberechtigt aber hat noch keine Spiele gespielt.
      </template>
    </div>

    <div
      v-else
      :class="b('stats', { inactive: !player.isDisabled && !playerHasNotPlayedAnyGame })"
    >
      <div
        v-for="(stat, index) in getFieldPlayerStats"
        :key="index"
        :class="b('stat-item', { mvp: stat.mvp })"
      >
        <span :class="b('stat-item-value')">
          {{ stat.value }}
        </span>
        <span :class="b('stat-item-label')">
          {{ stat.label }}
        </span>
      </div>
    </div>
  </v-card>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import ePlayerNumber from '@/elements/e-player-number.vue';
  import { CombinedFieldPlayerData, CombinedGoalKeeperData } from '@/types/team-details';
  import hdDataHelper from '@/helpers/hd-data-helper';

  type PlayerStat = {
    label: string;
    value: string | number;
    mvp: boolean;
  };

  // type Setup = {}
  // type Data = {}

  /**
   *
   */
  export default defineComponent({
    name: 'c-player-card',
    components: {
      ePlayerNumber,
    },

    // components: {},

    props: {
      /**
       * Player
       */
      fieldplayer: {
        type: Object as PropType<CombinedFieldPlayerData>,
        default: null,
      },

      /**
       * Players
       */
      goalkeeper: {
        type: Object as PropType<CombinedGoalKeeperData>,
        default: null,
      },

      /**
       * Show player image.
       */
      showPlayerImage: {
        type: Boolean,
        default: false,
      },

      /**
       * Player
       */
      isFemale: {
        type: Boolean,
        default: false
      },
    },
    // emits: [],

    // setup(): Setup {
    //   return {};
    // },
    // data(): Data {
    //   return {};
    // },

    computed: {
      player(): CombinedFieldPlayerData | CombinedGoalKeeperData {
        if (this.fieldplayer) {
          return this.fieldplayer;
        }

        if (this.goalkeeper) {
          return this.goalkeeper;
        }

        return {} as CombinedFieldPlayerData;
      },

      getFieldPlayerStats(): PlayerStat[] {
        return this.player.position === 'G' ? this.getGoalieStats : this.getPlayerStats;
      },

      getPlayerStats(): PlayerStat[] {
        const player = this.player as CombinedFieldPlayerData;

        return [
          { label: 'SP', value: player.gamesPlayed, mvp: player.gamesPlayedMVP },
          { label: 'Tore', value: player.goals, mvp: player.goalsMVP },
          { label: 'Assists', value: player.assists, mvp: player.assistsMVP },
          { label: 'PKT', value: player.points, mvp: player.pointsMVP },
          { label: 'SMIN', value: player.penaltyMinutes, mvp: player.penaltyMinutesMVP },
        ];
      },

      getGoalieStats(): PlayerStat[] {
        const player = this.player as CombinedGoalKeeperData;

        return [
          { label: 'SP', value: player.gamesPlayedIn, mvp: player.gamesPlayedInMVP },
          { label: 'SV%', value: `${player.savePercentage}%`, mvp: player.savePercentageMVP },
          { label: 'GA', value: player.goalsAgainst, mvp: player.goalsAgainstMVP },
          { label: 'GAA', value: player.goalsAgainstAverage, mvp: player.goalsAgainstAverageMVP },
          { label: 'SA', value: player.shotsAgainst, mvp: player.shotsAgainstMVP },
        ];
      },

      playerHasNotPlayedAnyGame(): boolean {
        let games = 0;

        if (this.player.position === 'G') {
          games = (this.player as CombinedGoalKeeperData).gamesPlayedIn;
        } else {
          games = this.player.gamesPlayed;
        }

        return games > 0;
      }
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      getFullname(lastname: string, firstname: string): string {
        return hdDataHelper.getPersonFullName(lastname, firstname);
      }
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use 'sass:map';
  @use 'vuetify/settings' as vuetify;
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-player-card {
    $this: &;

    display: flex;
    flex-direction: column;
    width: 320px;

    @include mixins.media(sm) {
      width: 100%;
    }

    &:not(:last-child) {
      margin-right: 25px;
    }

    &--disabled {
      opacity: 0.5;
    }

    &__image-container {
      position: relative;
    }

    &__jersey-number {
      position: absolute;
      top: 5px;
      left: 5px;
      z-index: 2;
    }

    &__image {
      height: 200px;
      z-index: 1;

      @include mixins.media(sm) {
        height: 240px;
      }

      &--team {
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        background-size: cover;

        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          backdrop-filter: blur(10px) grayscale(80%);
          background-color: rgba(map-get(vuetify.$grey, 'lighten-2'), 0.75);
        }
      }
    }

    &__header {
      border-bottom: 1px solid map.get(vuetify.$grey, 'lighten-2');

      .v-card-title {
        font-family: variables.$font-family-upbolters;
        font-size: variables.$font-size--h6;
        line-height: variables.$line-height--default !important; // stylelint-disable-line
      }

      .v-card-subtitle {
        font-size: variables.$font-size--xs;
        line-height: variables.$line-height--default !important; // stylelint-disable-line
        padding: 0 !important; // stylelint-disable-line
      }
    }

    &__stats {
      display: grid;
      grid-template-columns: repeat(5, 20%);

      &--inactive {
        opacity: 0.3;
      }
    }

    &__stat-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5px 0;
      color: map.get(vuetify.$grey, 'darken-1');
      border-right: 1px solid map.get(vuetify.$grey, 'lighten-2');
      border-top: 3px solid transparent;
      border-bottom: 3px solid transparent;
      background: map.get(vuetify.$grey, 'lighten-5');

      &--mvp {
        color: map.get(vuetify.$grey, 'darken-2');
        border-bottom: 2px solid map.get(vuetify.$grey, 'darken-1');
        background: rgba(map.get(vuetify.$amber, 'lighten-4'), 0.5);
      }

      &:last-child {
        border-right: 0;
      }
    }

    &__stat-item-value {
      font-family: variables.$font-family-babas;
      font-size: variables.$font-size--default;
      line-height: 1;
      font-weight: bold;
    }

    &__stat-item-label {
      font-family: variables.$font-family-default-condensed;
      font-size: variables.$font-size--xs;
      line-height: variables.$line-height--default;
    }

    &__fallback-text {
      font-family: variables.$font-family-default-condensed;
      font-size: variables.$font-size--xs;
      padding: 9px 20px;
      text-align: center;
      display: flex;
      flex-grow: 1;
      justify-content: center;
      align-items: center;
    }
  }
</style>
