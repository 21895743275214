import { Plugin } from 'vue';
// SSR related
import lTeam from '@/layouts/l-team.vue';
import cScheduleSlider from '@/components/c-schedule-slider.vue';

const plugin: Plugin = {
  install(app) {
    const components = [
      // SSR related
      lTeam,
      cScheduleSlider
    ];

    components.forEach((component) => {
      app.component(component.name || '', component);
    });
  },
};

export default plugin;
