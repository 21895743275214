import dayjs from 'dayjs';
import { defineStore } from 'pinia';
import { Store } from '@/setup/globals';
import config from '@/setup/js/config';
import { getApiUrlWithParams } from '@/helpers/get-api-url';
import { SelectOption } from '@/types/form-types';
import {
  CombinedFieldPlayerData,
  CombinedGoalKeeperData,
  MixedPlayer,
  TeamDetails,
  TeamDetailsGoalKeeperStats,
  TeamDetailsPlayer,
  TeamDetailsPlayerStats,
} from '@/types/team-details';

type Team = {
  id: number;
  teamLongname: string;
  teamShortname: string;
};

type TeamsState = {
  /**
   * Stores the data where the key is the divsionId.
   */
  teams: Team[];

  /**
   * Stores the team details.
   */
  teamDetails: Record<string, Record<string, TeamDetails>>;

  /**
   * Holds the info for running api calls.
   */
  runningRequests: {
    apiFetchTeams: boolean;
    apiFetchTeamDetails: boolean;
  };
};

function sortPlayer(playerA: MixedPlayer, playerB: MixedPlayer): number {
  const byNumber = playerB.playerJerseyNr - playerA.playerJerseyNr;

  if (playerA.position === 'G' && playerB.position === 'G') {
    playerA = playerA as TeamDetailsGoalKeeperStats;
    playerB = playerB as TeamDetailsGoalKeeperStats;

    const bySavePercentage = playerB.savePercentage - playerA.savePercentage;
    const byGames = playerB.gamesPlayedIn - playerA.gamesPlayedIn;

    return bySavePercentage || byGames || byNumber;
  }

  playerA = playerA as TeamDetailsPlayerStats;
  playerB = playerB as TeamDetailsPlayerStats;

  const byPoints = playerB.points - playerA.points;
  const byGames = playerB.gamesPlayed - playerA.gamesPlayed;

  return byPoints || byGames || byNumber;
}

function normalizeFieldplayer(
  players: TeamDetailsPlayerStats[],
  teamRoster: TeamDetailsPlayer[],
  teamGamesPlayed: number = 0
): CombinedFieldPlayerData[] {
  const output: CombinedFieldPlayerData[] = [];

  players.forEach((player) => {
    const hasOneGamePlayed = player.gamesPlayed > 0;
    const rosterPlayer = teamRoster.find((item) => item.id === player.id);
    const mvpData = {
      gamesPlayedMVP: hasOneGamePlayed && !players.find((item) => item.gamesPlayed > player.gamesPlayed),
      goalsMVP: hasOneGamePlayed && !players.find((item) => item.goals > player.goals),
      assistsMVP: hasOneGamePlayed && !players.find((item) => item.assists > player.assists),
      pointsMVP: hasOneGamePlayed && !players.find((item) => item.points > player.points),
      penaltyMinutesMVP: hasOneGamePlayed && !players.find((item) => item.penaltyMinutes > player.penaltyMinutes),
    };

    output.push({
      ...player,
      ...rosterPlayer,
      ...mvpData,
      ...getCustomData(player, teamGamesPlayed),
    } as CombinedFieldPlayerData);
  });

  output.sort(sortPlayer);

  return output;
}

function normalizeGoalkeepers(
  players: TeamDetailsGoalKeeperStats[],
  teamRoster: TeamDetailsPlayer[],
  teamGamesPlayed: number = 0
): CombinedGoalKeeperData[] {
  const output: CombinedGoalKeeperData[] = [];

  players.forEach((player) => {
    const hasOneGamePlayed = player.gamesPlayedIn > 0;
    const goaliesWithOnePlayedGame = players.filter((item) => item.gamesPlayedIn);

    const rosterPlayer = teamRoster.find((item) => item.id === player.id);
    const mvpData = {
      gamesPlayedInMVP: hasOneGamePlayed && !players.find((item) => item.gamesPlayedIn > player.gamesPlayedIn),
      savePercentageMVP: hasOneGamePlayed && !players.find((item) => item.savePercentage > player.savePercentage),
      goalsAgainstMVP:
        hasOneGamePlayed && !goaliesWithOnePlayedGame.find((item) => item.goalsAgainst < player.goalsAgainst),
      goalsAgainstAverageMVP:
        hasOneGamePlayed &&
        !goaliesWithOnePlayedGame.find((item) => item.goalsAgainstAverage < player.goalsAgainstAverage),
      shotsAgainstMVP:
        hasOneGamePlayed && !goaliesWithOnePlayedGame.find((item) => item.shotsAgainst < player.shotsAgainst),
    };

    output.push({
      ...player,
      ...rosterPlayer,
      ...mvpData,
      ...getCustomData(player, teamGamesPlayed),
    } as CombinedGoalKeeperData);
  });

  output.sort(sortPlayer);

  return output;
}

function getCustomData(player: TeamDetailsGoalKeeperStats | TeamDetailsPlayerStats, teamGamesPlayed: number = 0) {
  return {
    birthYear: dayjs(player.birthdate.value, 'YYYY-MM-DD').format('YYYY'),
    age: dayjs().diff(dayjs(player.birthdate.timestamp), 'years'),
    isDisabled: teamGamesPlayed > 0 && !player.gamesPlayed,
    teamImage: player.teamId ? `${config.LOGO_BASE_URL_API}/${player.teamId}.png` : '',
  };
}

const storeName = Store.Teams;

export default defineStore(storeName, {
  state: () => {
    const state: TeamsState = {
      teams: [],
      teamDetails: {},
      runningRequests: {
        apiFetchTeams: false,
        apiFetchTeamDetails: false,
      },
    };

    return state;
  },
  getters: {
    getTeamsAsOptions(state): SelectOption[] {
      return state.teams.map((team) => ({
        id: team.id,
        title: team.teamLongname,
        value: team.id,
      }));
    },
  },
  actions: {
    async apiFetchTeams(divisionId: number): Promise<void> {
      this.runningRequests.apiFetchTeams = true;

      // There is currently no other known way to fetch all teams per divisionId.
      const url = getApiUrlWithParams('getStatsTeamPowerplay', { divisionId });

      return this.$api
        .getJsonP(url)
        .then((response) => {
          const { data } = response || {};

          if (data?.rows) {
            const teams: Team[] = [];

            data.rows.forEach((team: Team) => {
              teams.push({
                id: team.id,
                teamLongname: team.teamLongname,
                teamShortname: team.teamShortname,
              });
            });

            teams.sort((a, b) => {
              if (a.teamLongname < b.teamLongname) {
                return -1;
              }

              if (a.teamLongname > b.teamLongname) {
                return 1;
              }

              return 0;
            });

            this.teams = teams;
          }
        })
        .finally(() => {
          this.runningRequests.apiFetchTeams = false;
        });
    },

    async apiFetchTeamDetails(teamId: string, divisionId: string): Promise<void> {
      this.runningRequests.apiFetchTeamDetails = true;

      if (!divisionId || !teamId) {
        this.runningRequests.apiFetchTeamDetails = false;

        return Promise.reject(new Error('No division ID or team ID'));
      }

      if (this.teamDetails?.[divisionId]?.[teamId]) {
        this.runningRequests.apiFetchTeamDetails = false;

        return Promise.resolve();
      }

      const url = getApiUrlWithParams('teamDetails', { teamId, divisionId });

      return this.$api
        .getJsonP(url)
        .then((response) => {
          const { data } = response || {};

          this.teamDetails[divisionId] = this.teamDetails?.[divisionId] || {};

          data.players = {};
          data.players.fieldplayers = normalizeFieldplayer(data.playerStats, data.teamRoster, data.teamStats?.gamesPlayed);
          data.players.goalkeepers = normalizeGoalkeepers(data.goalkeeperStats, data.teamRoster, data.teamStats?.gamesPlayed);

          // @ts-ignore next-line no clue why this reports an error.
          this.teamDetails[divisionId][teamId] = data || ({} as TeamDetails);
        })
        .finally(() => {
          this.runningRequests.apiFetchTeamDetails = false;
        });
    },
  },
});
