<template>
  <div :class="b()">
    <template v-for="section in teamRoster" :key="section.headline">
      <e-headline
        type="h4"
        :headline="section.headline"
        :class="b('headline')"
      />
      <component
        :is="$viewport.isMobile ? 'v-slide-group' : 'div'"
        :class="b('content-slider')"
        show-arrows
      >
        <template v-if="section.players">
          <c-player-card
            v-for="player in section.players"
            :key="player.id"
            :fieldplayer="player"
            :is-female="isWomansCup"
          />
        </template>
        <template v-else-if="section.goalies">
          <c-player-card
            v-for="player in section.goalies"
            :key="player.id"
            :goalkeeper="player"
            :is-female="isWomansCup"
          />
        </template>
      </component>
    </template>

    <c-legend-list
      :class="b('legend')"
      :items="legend"
    />
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import cPlayerCard from '@/components/c-player-card.vue';
  import eHeadline from '@/elements/e-headline.vue';
  import { CombinedFieldPlayerData, CombinedGoalKeeperData } from '@/types/team-details';
  import cLegendList from '@/components/c-legend-list.vue';

  // type Setup = {}
  type Data = {
    legend: string[];
  };

  type Roster = {
    headline: string;
    players: CombinedFieldPlayerData[] | null;
    goalies: CombinedGoalKeeperData[] | null;
  };

  /**
   *
   */
  export default defineComponent({
    name: 'c-team-roster-cards',
    components: {
      cLegendList,
      cPlayerCard,
      eHeadline,
    },

    props: {
      /**
       * Player
       */
      fieldplayers: {
        type: Array as PropType<CombinedFieldPlayerData[]>,
        default: () => [],
      },

      /**
       * Players
       */
      goalkeepers: {
        type: Array as PropType<CombinedGoalKeeperData[]>,
        default: () => [],
      },

      /**
       * Player
       */
      isWomansCup: {
        type: Boolean,
        default: false
      },
    },
    // emits: [],

    // setup(): Setup {
    //   return {};
    // },
    data(): Data {
      return {
        legend: [
          'SP = Spiele',
          'Tore = Geschossene Tore',
          'Assits = Assits für Tore gegeben',
          'PKT = Punkte insgesamt',
          'SMIN = Summe aller Strafminuten',
          'SV% = Fangquote',
          'GA = Tore kassiert',
          'GAA = Tore pro Spiel',
          'SA = Geblockte Schüsse',
          'gelb = MVP der Mannschaft in einem Bereich'
        ],
      };
    },

    computed: {
      forwards(): CombinedFieldPlayerData[] {
        return this.fieldplayers?.filter((player) => player.position === 'F') || [];
      },

      defense(): CombinedFieldPlayerData[] {
        return this.fieldplayers?.filter((player) => player.position === 'D') || [];
      },

      teamRoster(): Roster[] {
        return [
          {
            headline: this.isWomansCup ? 'Goalies' : 'Goalies',
            players: null,
            goalies: this.goalkeepers
          },
          {
            headline: this.isWomansCup ? 'Verteidigerinnen' : 'Verteidiger',
            players: this.defense,
            goalies: null
          },
          {
            headline: this.isWomansCup ? 'Stürmerinnen' : 'Stürmer',
            players: this.forwards,
            goalies: null
          }
        ];
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use 'sass:map';
  @use 'vuetify/settings' as vuetify;
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-team-roster-cards {
    $this: &;

    @include mixins.media(sm) {
      max-width: 1200px;
      margin: auto;
    }

    &__headline {
      text-align: center;
    }

    &__content-slider {
      margin-bottom: 80px;
      position: relative;

      @include mixins.media(sm) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
      }

      @include mixins.media(lg) {
        grid-template-columns: repeat(4, 1fr);
      }

      .v-slide-group__content {
        padding: 20px 2px;
        scroll-snap-type: x mandatory;
      }

      .v-slide-group__prev,
      .v-slide-group__next {
        position: absolute;
        height: 100%;
        background: rgba(variables.$color-grayscale--1000, 0.7);
        backdrop-filter: blur(5px);
        z-index: 9;

        @include mixins.media($down: sm) {
          min-width: 20px;
        }
      }

      .v-slide-group__prev {
        left: 0;
      }

      .v-slide-group__next {
        right: 0;
      }

      .v-slide-group__next--disabled,
      .v-slide-group__prev--disabled {
        width: 0;
        min-width: 0;
        overflow: hidden;
        display: none;
      }
    }

    &__legend {
      margin-top: 6rem;
    }
  }
</style>
